import React, { useEffect, useState } from "react";
import { Close, DeleteForever, Edit, Search } from "@styled-icons/material";
import { ClipLoader } from "react-spinners";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  DivBtnEdit,
  DivBtnSearch,
  DivIdPosition,
  DivPosition,
  DivPositionInfo,
  DivSearchPosition,
  DivTableSearch,
  DivInfo,
  TitleSearchPosition,
  NameInput,
  NameLabel,
  SpanCod,
  SpanName,
  DivOrgLoading,
  DivOrgInfo,
  DivOrgCard,
  DivFilter,
  CodInput,
  DivOrgFilter,
  BtnCancel,
} from "./SearchWorkPositionStyle";
import UpdatePosition from "../../Update/UpdateWorkPosition/UpdateWorkPosition";
import DeletePosition from "../../DeleteComponent/DeletePosition/DeletePosition";

export default function SearchPosition(props) {
  const positionDetail = props.positionsInfo;

  // const [positionInfo, setPositionInfo] = useState([]);
  const [positionPopUp, setPositionPopUp] = useState(false);
  const [delPositionOption, setDelPositionOption] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();
  const [filterCodPosition, setFilterCodPosition] = useState("");
  const [filterNamePosition, setFilterNamePosition] = useState("");
  const [filterInfoPosition, setFilterInfoPosition] = useState([]);

  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState();
  const [loadingPositions, setLoadingPositions] = useState(false);
  const [dataPositionUpdate, setDataPositionUpdate] = useState([]);

  const createListPositions = (dataPosition) => {
    setLoading(true);
    setShowList(true);
    if (showList && dataPosition) {
      setFilterInfoPosition(dataPosition);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterPosition = () => {
    const filterList = positionDetail
      .filter((position) =>
        filterNamePosition.length > 0
          ? position.namePosition.includes(
              filterNamePosition.toLocaleLowerCase()
            )
          : position
      )
      .filter((position) =>
        filterCodPosition > 0
          ? position.idPosition === filterCodPosition
          : position
      );

    setFilterInfoPosition(filterList);
  };

  useEffect(() => {
    createListPositions(positionDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionDetail]);

  useEffect(() => {
    setLoading(true);
    if (loadingPositions) {
      props.getListPositions();
    }
    setTimeout(() => {
      setLoadingPositions(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPositions]);

  return (
    <DivSearchPosition>
      <DivFilter>
        <TitleSearchPosition>Consultar Profissões</TitleSearchPosition>
        <DivOrgFilter show={props.disableFilter}>
          <NameLabel>Nome</NameLabel>
          <NameInput
            value={filterNamePosition}
            onChange={(e) => setFilterNamePosition(e.target.value)}
          />
          <NameLabel>Codigo</NameLabel>
          <CodInput
            value={filterCodPosition}
            onValueChange={(values, sourceInfo) => {
              setFilterCodPosition(parseInt(values.value));
            }}
          />
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterPosition}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodPosition("");
                setFilterNamePosition("");
                setFilterInfoPosition(positionDetail);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivOrgFilter>
      </DivFilter>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoPosition?.map((positionInfo, index) => {
            return (
              <DivPosition key={index}>
                <DivOrgCard>
                  <DivInfo>
                    <DivIdPosition>{index + 1}</DivIdPosition>
                    <DivPositionInfo>
                      <SpanName>
                        Profissão: {parseName(positionInfo.namePosition)}
                      </SpanName>
                      <DivOrgInfo>
                        <SpanCod>Codigo: {positionInfo.idPosition}</SpanCod>
                        {/* <SpanCod>Valor: {positionInfo.valuePosition}</SpanCod> */}
                      </DivOrgInfo>
                    </DivPositionInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setPositionPopUp(!positionPopUp);
                        setDataPositionUpdate(positionInfo);
                        setSelectedPosition(positionInfo);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {positionPopUp &&
                      positionInfo.idPosition ===
                        selectedPosition.idPosition && (
                        <UpdatePosition
                          setLoadingPositions={setLoadingPositions}
                          dataPositionUpdate={dataPositionUpdate}
                          PositionPopUp={positionPopUp}
                          setPositionPopUp={setPositionPopUp}
                        />
                      )}
                    <BtnRemove
                      onClick={() => {
                        setDelPositionOption(!delPositionOption);
                        setSelectedPosition(positionInfo);
                      }}
                    >
                      <DeleteForever />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {delPositionOption &&
                  positionInfo.idPosition === selectedPosition.idPosition && (
                    <DeletePosition
                      setLoadingPositions={setLoadingPositions}
                      selectedPosition={selectedPosition}
                      delPositionOption={delPositionOption}
                      setDelPositionOption={setDelPositionOption}
                    />
                  )}
              </DivPosition>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchPosition>
  );
}
