import React, { useState, useEffect } from "react";
import {
  DivEmployeeRegister,
  TitleEmployeeReg,
  DivScreenEmployee,
} from "./EmployeeRegisterStyle";
import NewEmployee from "../../components/Forms/NewEmployee/NewEmployee";
import SearchEmployee from "../../components/Search/SearchEmployee/SearchEmployee";
import { useDispatch } from "react-redux";
import { allEmployees } from "../../store/registers/employee/employee.actions";
import { allPosition } from "../../store/registers/workPosition/position.actions";

export default function EmployeeRegister() {
  const dispatch = useDispatch();
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [positionInfo, setPositionInfo] = useState([]);
  const [disableFilter, setDisableFilter] = useState(false);

  const getListEmployees = async () => {
    const listEmployees = await dispatch(allEmployees());
    setEmployeeInfo(listEmployees.payload);
  };

  const getListPosition = async () => {
    const listPositions = await dispatch(allPosition());
    setPositionInfo(listPositions.payload);
  };

  useEffect(() => {
    getListEmployees();
    getListPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (employeeInfo.errorStatus === true) {
      alert(employeeInfo.message);
      setDisableFilter(true);
    } else {
      setDisableFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeInfo.errorStatus]);

  return (
    <DivEmployeeRegister>
      <TitleEmployeeReg>Cadastro de Funcionarios</TitleEmployeeReg>
      <DivScreenEmployee>
        <NewEmployee
          getListEmployees={getListEmployees}
          positionInfo={positionInfo?.positions}
        />
        <SearchEmployee
          employeeInfo={employeeInfo?.employee}
          getListEmployees={getListEmployees}
          disableFilter={disableFilter}
        />
      </DivScreenEmployee>
    </DivEmployeeRegister>
  );
}
